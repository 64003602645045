html, body {
  height: 100vh !important;
  scroll-behavior: smooth;
  /*overflow: hidden !important;*/
}

.sheet {
  position: relative;
  width: 100%;
  height: 110vh;
  margin-bottom: -10vh;
  transition: transform 1500ms ease;
  transform-origin: top center;
  overflow: hidden;
}

.sheet .content {
  height: 100vh;
}

.sheet.page1,
.sheet.page1 .content {
  transform: none;
  margin: 0;
  height: 100vh;
}

.sheet.technologies {
  height: 100vh;
  margin-bottom: 0;
}

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 #f700ff, 2px -3px 0 #00d0ff;
    color: #fff;
  }
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 #f700ff, -2px 3px 0 #00d0ff;
  }
  100% {
    text-shadow: none;
    color: #3c3e41;
  }
}

@keyframes glitchImage {
  0% {
    text-shadow: -2px 3px 0 #f700ff, 2px -3px 0 #00d0ff;
    transform: translate(var(--glitch-translate));
    background-image: url('assets/avatar-glitched2.png');
    opacity: 0.5;
  }
  2% {
    text-shadow: 2px -3px 0 #f700ff, -2px 3px 0 #00d0ff;
    background-image: url('assets/avatar-glitched2.png');
    opacity: 0.5;
  }
  4%, 100% {
    text-shadow: none;
    transform: none;
    background-image: url('assets/avatar.png');
    opacity: 1;
  }
}

@keyframes glitchImageAlternative {
  0% {
    text-shadow: -2px 3px 0 #f700ff, 2px -3px 0 #00d0ff;
    transform: translate(var(--glitch-translate));
    background-image: url('assets/avatar-glitched.png');
  }
  2% {
    text-shadow: 2px -3px 0 #f700ff, -2px 3px 0 #00d0ff;
    background-image: url('assets/avatar-glitched.png');
  }
  4%, 100% {
    text-shadow: none;
    transform: none;
    background-image: url('assets/avatar.png');
  }
}

@keyframes flicker {
  0% {
    opacity: .1;
  }
  50% {
    opacity: .15;
  }
  4%, 100% {
    opacity: 0;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 #f700ff, 2px -3px 0 #00d0ff;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 #f700ff, -2px 3px 0 #00d0ff;
  }
  4%, 100% {
    text-shadow: none;
    transform: none;
  }
}
